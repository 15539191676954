import {useEffect} from "react";
import {useRouter} from "next/router";
import {IntlProvider} from "react-intl";
import {useSelector} from "react-redux";
import dynamic from 'next/dynamic'

const ToastProvider = dynamic(() => import("./atoms/Toast/ToastProvider"))
const Layout = dynamic(() => import('./components/Layout'))
const AccountLayout = dynamic(() => import("./components/account/AccountLayout"))
import {IState} from "./types/state";
import {NextComponentType, NextPageContext} from "next/dist/shared/lib/utils";
import {PropsConsumer} from "./context";

interface IWrapProps {
    Component: NextComponentType<NextPageContext, any, any>,
}


const CustomWrapper: React.FC<IWrapProps> = ({Component}) => {
    const customer = useSelector((state: IState) => state.customer);
    const translations = useSelector((state: IState) => state.general.translations);
    const router = useRouter();

    const isItAccountPage = router.pathname.indexOf("account") != -1;

    useEffect(() => {

        const loadData = async () => {
            if (
                isItAccountPage &&
                !customer.token &&
                !customer.authenticated

            ) {
                await router.push("/");
                // return null;
            } else if (
                isItAccountPage &&
                customer.token &&
                customer.authenticated &&
                router.pathname === "/signin"
            ) {
                await router.push("/account/profile");
                // return null;
            }
        };

        loadData();
    }, []);

    /*FIXME only use this in dev mode!!!!*/
    // return typeof window !== "undefined" ? (
    return (
        <PropsConsumer>
            {
                props => (

                    <IntlProvider
                        //@ts-ignore
                        locale={props.locale}
                        //@ts-ignore
                        messages={translations[props.locale]?.translations}
                    >
                        <ToastProvider>
                            {/*// @ts-ignore*/}
                            <Layout locale={props.locale}>
                                {
                                    isItAccountPage && customer.token && customer.authenticated
                                        ? (
                                            <AccountLayout>
                                                <Component {...props} />
                                            </AccountLayout>
                                        )
                                        : (
                                            <Component {...props} />
                                        )
                                }
                            </Layout>
                        </ToastProvider>
                    </IntlProvider>
                )
            }
        </PropsConsumer>
    )
}

export default CustomWrapper;
